import React from 'react';
import styled from 'styled-components';

const Wrapper = styled.section`
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
`;

const Entry = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
`;

const Square = styled.div`
  width: 2.3vh;
  height: 2.3vh;
  background-color: ${props => props.currentKeyColor || 'palevioletred'};
  margin-right: 2vh;
`;

const Number = styled.div`
  text-align: center;
  font-size: 5vh;
  padding: 1vh 0;
  width: 3ch;
  border-bottom: solid 3px ${props => props.currentKeyColor || 'palevioletred'};
`;

const Label = styled.div`
  color: #f6f4f2;
  font-size: 2vh;
  line-height: 2.5vh;
  text-align: center;
  margin-left: 1vh;
  opacity: 0.5;
  width: 3ch;
`;

const RightSection = props => {
  return (
    <Wrapper>
      <Entry>
        <Square currentKeyColor={props.downFive.color} />
        <Number currentKeyColor={props.downFive.color}>
          {props.downFive.number}
          {props.downFive.minor ? 'A' : 'B'}
        </Number>
        <Label>-5</Label>
      </Entry>
      <Entry>
        <Square currentKeyColor={props.downTwo.color} />
        <Number currentKeyColor={props.downTwo.color}>
          {props.downTwo.number}
          {props.downTwo.minor ? 'A' : 'B'}
        </Number>
        <Label>-2</Label>
      </Entry>
      <Entry>
        <Square currentKeyColor={props.downOne.color} />
        <Number currentKeyColor={props.downOne.color}>
          {props.downOne.number}
          {props.downOne.minor ? 'A' : 'B'}
        </Number>
        <Label>-1</Label>
      </Entry>
      <Entry>
        <Square currentKeyColor={props.currentNumberData.color} />
        <Number currentKeyColor={props.currentNumberData.color}>
          {props.currentNumberData.number}
          {props.currentNumberData.minor ? 'A' : 'B'}
        </Number>
        <Label>{props.currentNumberData.minor ? 'min' : 'maj'}</Label>
      </Entry>
      <Entry>
        <Square currentKeyColor={props.upOne.color} />
        <Number currentKeyColor={props.upOne.color}>
          {props.upOne.number}
          {props.upOne.minor ? 'A' : 'B'}
        </Number>
        <Label>+1</Label>
      </Entry>
      <Entry>
        <Square currentKeyColor={props.upTwo.color} />
        <Number currentKeyColor={props.upTwo.color}>
          {props.upTwo.number}
          {props.upTwo.minor ? 'A' : 'B'}
        </Number>
        <Label>+2</Label>
      </Entry>
      <Entry>
        <Square currentKeyColor={props.upFive.color} />
        <Number currentKeyColor={props.upFive.color}>
          {props.upFive.number}
          {props.upFive.minor ? 'A' : 'B'}
        </Number>
        <Label>+5</Label>
      </Entry>
    </Wrapper>
  );
};

export default RightSection;
