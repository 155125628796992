import React from 'react';
import styled from 'styled-components';
import { DebounceInput } from 'react-debounce-input';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faChevronUp,
  faChevronDown,
  faChevronLeft,
  faChevronRight
} from '@fortawesome/pro-light-svg-icons';

const Wrapper = styled.section`
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
`;

const Entry = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
`;

const Number = styled.div`
  text-align: center;
  width: 3ch;
  font-size: 6vh;
  padding: 1vh 0;
  opacity: 1;
  border-bottom: solid 3px ${props => props.currentKeyColor || 'palevioletred'};
`;

const UpDown = styled.div`
  text-align: center;
`;

const InputSection = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  button {
    cursor: pointer;
    background: transparent;
    color: #f6f4f2;
    font-size: 2vh;
    padding: 1vh;
    border: 0;
    transition: 0.5s ease-in-out;
    opacity: 1;
    border-radius: 5px;
    opacity: 0.5;
    &:disabled {
      border: none;
      cursor: not-allowed;
      opacity: 0.1;
    }
    &:hover {
      opacity: 1;
    }
  }
`;

const Form = styled.form`
  input {
    -moz-appearance: textfield;
    background: transparent;
    border: none;
    padding: 1vh;
    font-size: 6vh;
    max-width: 3ch;
    color: ${props => props.currentKeyColor || 'palevioletred'};
    text-align: center;
    &::-webkit-inner-spin-button,
    &-::-webkit-outer-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }
    &::placeholder {
      color: ${props => props.currentKeyColor || 'palevioletred'};
    }
  }
`;

const Label = styled.div`
  color: #f6f4f2;
  font-size: 2vh;
  line-height: 2.5vh;
  text-align: center;
  width: 5ch;
  margin-left: 1vh;
  opacity: 0.5;
`;

const LeftSection = props => {
  return (
    <Wrapper>
      <Entry>
        <Number currentKeyColor={props.downTwoSt.color}>
          {props.downTwoSt.number}
          {props.currentlyMinorKey ? 'A' : 'B'}
        </Number>
        <Label>-2 st</Label>
      </Entry>
      <Entry>
        <Number currentKeyColor={props.downOneSt.color}>
          {props.downOneSt.number}
          {props.currentlyMinorKey ? 'A' : 'B'}
        </Number>
        <Label>-1 st</Label>
      </Entry>
      <Entry>
        <InputSection>
          <button
            onClick={props.toggleMakeMinor}
            disabled={props.currentlyMinorKey}
          >
            <FontAwesomeIcon icon={faChevronLeft} />
          </button>
          <UpDown>
            <button onClick={props.goUp}>
              <FontAwesomeIcon icon={faChevronUp} />
            </button>
            <Form current currentKeyColor={props.currentNumberData.color}>
              <DebounceInput
                minLength={1}
                debounceTimeout={300}
                value={''}
                placeholder={props.composedKey}
                onChange={props.handleSubmit}
              />
            </Form>
            <button onClick={props.goDown}>
              <FontAwesomeIcon icon={faChevronDown} />
            </button>
          </UpDown>
          <button
            onClick={props.toggleMakeMinor}
            disabled={!props.currentlyMinorKey}
          >
            <FontAwesomeIcon icon={faChevronRight} />
          </button>
        </InputSection>
        <Label />
      </Entry>
      <Entry>
        <Number currentKeyColor={props.upOneSt.color}>
          {props.upOneSt.number}
          {props.currentlyMinorKey ? 'A' : 'B'}
        </Number>
        <Label>+1 st</Label>
      </Entry>
      <Entry>
        <Number currentKeyColor={props.upTwoSt.color}>
          {props.upTwoSt.number}
          {props.currentlyMinorKey ? 'A' : 'B'}
        </Number>
        <Label>+2 st</Label>
      </Entry>
    </Wrapper>
  );
};

export default LeftSection;
