const keys = [
  {
    number: 1,
    minor: true,
    camelot: '1A',
    key: 'A-Flat Minor',
    color: '#FD5873'
  },
  {
    number: 1,
    minor: false,
    camelot: '1B',
    key: 'B Major',
    color: '#8C2B2C'
  },
  {
    number: 2,
    minor: true,
    camelot: '2A',
    key: 'E-Flat Minor',
    color: '#EE8C23'
  },
  {
    number: 2,
    minor: false,
    camelot: '2B',
    key: 'F-Sharp Major',
    color: '#87422A'
  },
  {
    number: 3,
    minor: true,
    camelot: '3A',
    key: 'B-Flat Minor',
    color: '#A37B29'
  },
  {
    number: 3,
    minor: false,
    camelot: '3B',
    key: 'D-Flat Major',
    color: '#5C4036'
  },
  {
    number: 4,
    minor: true,
    camelot: '4A',
    key: 'F Minor',
    color: '#F3EF4F'
  },
  {
    number: 4,
    minor: false,
    camelot: '4B',
    key: 'A-Flat Major',
    color: '#B09D1E'
  },
  {
    number: 5,
    minor: true,
    camelot: '5A',
    key: 'C Minor',
    color: '#9ACA22'
  },
  {
    number: 5,
    minor: false,
    camelot: '5B',
    key: 'E-Flat Major',
    color: '#6C7A21'
  },
  {
    number: 6,
    minor: true,
    camelot: '6A',
    key: 'G Minor',
    color: '#19E129'
  },
  {
    number: 6,
    minor: false,
    camelot: '6B',
    key: 'B-Flat Major',
    color: '#447F2C'
  },
  {
    number: 7,
    minor: true,
    camelot: '7A',
    key: 'D Minor',
    color: '#1CEA8F'
  },
  {
    number: 7,
    minor: false,
    camelot: '7B',
    key: 'F Major',
    color: '#107E73'
  },
  {
    number: 8,
    minor: true,
    camelot: '8A',
    key: 'A Minor',
    color: '#2DFEDF'
  },
  {
    number: 8,
    minor: false,
    camelot: '8B',
    key: 'C Major',
    color: '#1E506B'
  },
  {
    number: 9,
    minor: true,
    camelot: '9A',
    key: 'E Minor',
    color: '#4DA4FC'
  },
  {
    number: 9,
    minor: false,
    camelot: '9B',
    key: 'G Major',
    color: '#152578'
  },
  {
    number: 10,
    minor: true,
    camelot: '10A',
    key: 'B Minor',
    color: '#2559D7'
  },
  {
    number: 10,
    minor: false,
    camelot: '10B',
    key: 'D Major',
    color: '#4F3C89'
  },
  {
    number: 11,
    minor: true,
    camelot: '11A',
    key: 'F-Sharp Minor',
    color: '#5373FC'
  },
  {
    number: 11,
    minor: false,
    camelot: '11B',
    key: 'A Major',
    color: '#4F3C89'
  },
  {
    number: 12,
    minor: true,
    camelot: '12A',
    key: 'D-Flat Minor',
    color: '#C93BD8'
  },
  {
    number: 12,
    minor: false,
    camelot: '12B',
    key: 'E Major',
    color: '#823D8A'
  }
];

export default keys;
