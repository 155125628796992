import React from 'react';
import styled from 'styled-components';
import Div100vh from 'react-div-100vh';
import LeftSection from './LeftSection.js';
import RightSection from './RightSection.js';
import notes from './keyColors';

const Wrapper = styled.main`
  height: 100%;
  display: grid;
  grid-template-columns: 6% 47% 47%;
  background: #373737;
  color: #f6f4f2;
`;

const Header = styled.header`
  background-color: #f59e27;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #f6f4f2;
  writing-mode: vertical-rl;
  text-orientation: mixed;
  font-size: 3vh;
`;

class App extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      currentNumber: 0,
      currentlyMinorKey: true,
      composedKey: '1A',
      currentNumberData: {},
      oppositeCurrentNumberData: {},
      upOneSt: {},
      upTwoSt: {},
      downOneSt: {},
      downTwoSt: {},
      upOne: {},
      upTwo: {},
      downOne: {},
      downTwo: {}
    };
    this.toggleMakeMinor = this.toggleMakeMinor.bind(this);
  }

  componentDidMount() {
    this.setState({ currentNumber: 1 });
    this.updateCurrentNumber(1, true);
    this.updateEverything(1, true);
  }

  magicFilter(number, minor) {
    var query = {
      number: number,
      minor: minor
    };
    function search(note) {
      return Object.keys(this).every(key => note[key] === this[key]);
    }
    return notes.filter(search, query);
  }

  handleSubmit = e => {
    let numberSubmitted = parseInt(e.target.value);
    if (numberSubmitted && numberSubmitted <= 12 && numberSubmitted > 0) {
      this.updateCurrentNumber(numberSubmitted, this.state.currentlyMinorKey);
      this.updateEverything(numberSubmitted, this.state.currentlyMinorKey);
      e.target.value = '';
      e.target.placeholder = this.state.composedKey;
    } else {
      e.target.value = '';
    }
  };

  toggleMakeMinor() {
    this.updateCurrentNumber(
      this.state.currentNumber,
      !this.state.currentlyMinorKey
    );
    this.updateEverything(
      this.state.currentNumber,
      !this.state.currentlyMinorKey
    );
    this.setState({ currentlyMinorKey: !this.state.currentlyMinorKey });
  }

  updateSemitones(number, minor) {
    let upOneSt = number - 5;
    if (upOneSt < 1) {
      upOneSt = 12 + upOneSt;
    }
    upOneSt = this.magicFilter(upOneSt, minor);
    this.setState({ upOneSt: upOneSt[0] });
    let upTwoSt = number - 10;
    if (upTwoSt < 1) {
      upTwoSt = 12 + upTwoSt;
    }
    upTwoSt = this.magicFilter(upTwoSt, minor);
    this.setState({ upTwoSt: upTwoSt[0] });
    let downOneSt = number + 5;
    if (downOneSt > 12) {
      downOneSt = downOneSt - 12;
    }
    downOneSt = this.magicFilter(downOneSt, minor);
    this.setState({ downOneSt: downOneSt[0] });
    let downTwoSt = number + 10;
    if (downTwoSt > 12) {
      downTwoSt = downTwoSt - 12;
    }
    downTwoSt = this.magicFilter(downTwoSt, minor);
    this.setState({ downTwoSt: downTwoSt[0] });
  }

  getOppositeCurrentNumber(number, minor) {
    let oppositeCurrentNumberData = this.magicFilter(number, !minor);
    this.setState({ oppositeCurrentNumberData: oppositeCurrentNumberData[0] });
  }

  getCompatibleKeys(number, minor) {
    let upOne = number + 1;
    if (upOne > 12) {
      upOne = upOne - 12;
    }
    upOne = this.magicFilter(upOne, minor);
    this.setState({ upOne: upOne[0] });
    let upTwo = number + 2;
    if (upTwo > 12) {
      upTwo = upTwo - 12;
    }
    upTwo = this.magicFilter(upTwo, minor);
    this.setState({ upTwo: upTwo[0] });
    let downOne = number - 1;
    if (downOne < 1) {
      downOne = 12 + downOne;
    }
    downOne = this.magicFilter(downOne, minor);
    this.setState({ downOne: downOne[0] });
    let downTwo = number - 2;
    if (downTwo < 1) {
      downTwo = 12 + downTwo;
    }
    downTwo = this.magicFilter(downTwo, minor);
    this.setState({ downTwo: downTwo[0] });
  }

  updateCurrentNumber(number, minor) {
    this.setState({ currentNumber: number });
    let newCurrentNumber = this.magicFilter(number, minor);
    this.setState({
      currentNumberData: newCurrentNumber[0],
      composedKey: number + (minor ? 'A' : 'B')
    });
  }

  updateEverything(number, minor) {
    this.getCompatibleKeys(number, minor);
    this.getOppositeCurrentNumber(number, minor);
    this.updateSemitones(number, minor);
    this.setState({
      composedKey: number + (minor ? 'A' : 'B')
    });
  }

  goUp = e => {
    document.querySelector('input').value = '';
    let newNumber = this.state.currentNumber + 1;
    if (newNumber <= 12) {
      this.setState({ currentNumber: newNumber });
      this.updateCurrentNumber(newNumber, this.state.currentlyMinorKey);
      this.updateEverything(newNumber, this.state.currentlyMinorKey);
    } else {
      this.setState({ currentNumber: 1 });
      this.updateCurrentNumber(1, this.state.currentlyMinorKey);
      this.updateEverything(1, this.state.currentlyMinorKey);
    }
  };

  goDown = e => {
    let newNumber = this.state.currentNumber - 1;
    if (newNumber >= 1) {
      this.setState({ currentNumber: newNumber });
      this.updateCurrentNumber(newNumber, this.state.currentlyMinorKey);
      this.updateEverything(newNumber, this.state.currentlyMinorKey);
    } else {
      this.setState({ currentNumber: 12 });
      this.updateCurrentNumber(12, this.state.currentlyMinorKey);
      this.updateEverything(12, this.state.currentlyMinorKey);
    }
  };

  render() {
    return (
      <Div100vh>
        <Wrapper>
          <Header>Harmonic Mixing Calculator</Header>
          <LeftSection
            handleSubmit={this.handleSubmit}
            toggleMakeMinor={this.toggleMakeMinor}
            upOneSt={this.state.upOneSt}
            upTwoSt={this.state.upTwoSt}
            currentNumberData={this.state.currentNumberData}
            currentlyMinorKey={this.state.currentlyMinorKey}
            composedKey={this.state.composedKey}
            goUp={this.goUp}
            goDown={this.goDown}
            downOneSt={this.state.downOneSt}
            downTwoSt={this.state.downTwoSt}
          />
          <RightSection
            downFive={this.state.upOneSt}
            downTwo={this.state.downTwo}
            downOne={this.state.downOne}
            currentNumberData={this.state.oppositeCurrentNumberData}
            currentlyMinorKey={this.state.currentlyMinorKey}
            upOne={this.state.upOne}
            upTwo={this.state.upTwo}
            upFive={this.state.downOneSt}
          />
        </Wrapper>
      </Div100vh>
    );
  }
}

export default App;
